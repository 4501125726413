.feature {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 20px; /* Adjust for desired curvature */
    padding: 5px; /* Adjust for desired spacing */
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    margin-left: -5px;
    margin-bottom: 5px;
}

.feature__icon {
    color: #737373;
    margin-right: 6px; /* Spacing between icon and label */
}

.feature__label {
    white-space: nowrap;
}
