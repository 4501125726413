* {
  box-sizing: border-box;
}

body > #root > div {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f4;
}

header {
  display: grid;
  place-content: center;
  margin-top: 35vh;
  margin-bottom: 20vh;
}

h1 {
  font-size: 7rem;
  font-weight: 600;
  font-family: 'Chakra Petch', sans-serif;
  line-height: 5.9rem;
  overflow: hidden; 
  margin: 0 15px; /* Adds spacing between names, adjust as needed */
  white-space: nowrap;
  opacity: 0;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  opacity: 0;
}

h3 {
  font-size: 3.5rem;
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 600;
  color: #338CFF;
  margin: 0px;
}

@media (max-width: 768px) { /* Adjust the max-width as needed for your breakpoint */
  h1 {
    font-size: 4.5rem; /* Smaller font size for mobile devices */
    line-height: 3.7rem; /* Adjust line height accordingly */
  }
}