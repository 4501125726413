.swiperimage {
    height: 100%;
    object-fit: cover;
}

.modal {
    min-height: 600px;
    border-radius: 20px;
    position: relative;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display:flex;
    overflow: hidden;
}

.modal__row {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;
}

.modal__title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
}

.modal__type {
    font-size: 1.5rem;
    color: #737373;
}

.swiper {
    object-fit:cover;
    padding-bottom: 30px !important;
    border-radius: 20px;
}

.modal__info {
    background-color: white;
    display: flex;
}

.modal__description-wrapper {
    text-align: left;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.modal__description {
    color: #737373;
    margin: 0px;
    padding: 0px;
    font-size: 1.25rem;
}

.modal__close-wrapper {
    all: unset;
    position: absolute;
    cursor: pointer;
}

.modal__close-icon {
    width: 40px;
    height: 40px;
}

.modal__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    background: black;
    border-radius: 25px; 
    padding: 5px; /* Adjust for desired spacing */
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;
    margin-top: -5px;
    margin-left: -5px;
    margin-bottom: 5px;
  }
  
.modal__button-icon {
    width: 20px;
    height: 20px;
    color: white;
    z-index: 1;
}

.modal__button-text {
    font-family: 'Saira', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: white;
    white-space: nowrap;
    margin-left: 10px;
}

.modal__button:hover {  background: white; }
.modal__button:hover .modal__button-text {  color: black;  }
.modal__button:hover .modal__button-icon {  color: black;  }

.swiper-button-next,
.swiper-button-prev {
    width:10rem !important;
    height:10rem !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: var(--swiper-navigation-top-offset, 40%) !important;
    /* width: calc(var(--swiper-navigation-size) / 44 * 27) !important;
    height: var(--swiper-navigation-size) !important; */
}

.swiper-button-next::after,
.swiper-button-prev::after {
    display: none
}

.swiper-button-prev {
    background-image: url("https://res.cloudinary.com/dv5ot0eg0/image/upload/v1704143569/left_arrow_long.png");
    left: var(--swiper-navigation-sides-offset, -30px) !important;
}

.swiper-button-next {
    background-image: url("https://res.cloudinary.com/dv5ot0eg0/image/upload/v1704143569/right_arrow_long.png");
    right: var(--swiper-navigation-sides-offset, -30px) !important;
}

.swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
    background: gray !important;
    opacity: 0.7 !important;
}

.swiper-pagination-bullet-active {
    width: 16px !important;
    height: 16px !important;
    background: black !important; 
    opacity: 1 !important;
    transform: translateY(2px);
}

.swiper-pagination {
    transform: translateY(10px) !important;
}

.swiper-scrollbar {
    height: 10px !important;
}

@media only screen {
    .modal {
        width: 90%;
        height: auto;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
    }

    .swiper {
        width: 100vh;
        height: 100vh;
    }

    .modal__info {
        width: 100%;
        height: 100%;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .modal__close-wrapper {
        right: -20px;
        top: -30px;
    }

    .modal__close-icon {
        width: 35px;
        height: 35px;
        color: #cacaca;
    }
}

@media only screen and (min-width: 1281px) {
    .modal {
        width: 80%;
        height: 80%;
        max-width: 1800px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 20px;
    }

    .swiper {
        width: calc(70% - 10px);
        height: 100%;
    }

    .modal__info {
        width: calc(30% - 10px);
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .modal__close-wrapper {
        right: 10px;
        top: 10px;
    }

    .modal__close-icon {
        color: #737373
    }
}