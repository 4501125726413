.project {
    width: 25%;
    min-width: 300px;
    max-width: 400px;
    position: relative;
    margin: 30px;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    opacity: 0;
}

.project.no-thumbnail {
    cursor: auto;
    max-height: 150px;
    margin-left: 50vh;
    margin-right: 50vh;
}

.project::before {
    content: "";
    display: block;
    padding-bottom: var(--project-padding-bottom, calc((5/4) * 100%));
}

.project:last-child::before {
    content: "";
    display: block;
    padding-bottom: var(--project-padding-bottom, calc((3/8) * 100%));
}

.project__content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.project__image-container {
    width: 100%;
    height: 75%;
    overflow: hidden;
}

.project__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project__details {
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 0px 25px;
    position: relative;
}

.project__details.no-thumbnail {
    height: 100%;
}

.project__row {
    display: flex;
    margin: 0px 0px;
    align-items: center;
}

.project__type {
    margin-top: -10px;
}

.project__title {
    font-size: 1.5rem;
    font-family: 'Saira', sans-serif;
    font-weight: 700;
    white-space: nowrap;
}

.project__type {
    color: #737373;
    font-family: 'Saira', sans-serif;
    font-weight: 400;
}

.project__git-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    background: black;
    border-radius: 25px; 
    padding: 5px; /* Adjust for desired spacing */
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;
    margin-top: -5px;
    margin-left: -5px;
    margin-bottom: 5px;
  }
  
.project__git-button-icon {
    width: 20px;
    height: 20px;
    color: white;
    z-index: 1;
}

.project__git-button-text {
    font-family: 'Saira', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: white;
    white-space: nowrap;
    margin-left: 10px;
}

.project__git-button:hover {  background: white; }
.project__git-button:hover .project__git-button-text {  color: black;  }
.project__git-button:hover .project__git-button-icon {  color: black;  }

@media (max-width: 1200px) {
    .project__details {
        padding: 0px 15px;
    }
}